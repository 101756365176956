import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLanguage } from "../contexts/LanguageContext"

const MovablesList = ({ movables = [] }) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  return (
    <div className="jobs-list">
      {movables.map(movable => {
        const { id, naslov, slika, vrijediDo } = movable
        return (
          <div key={id} className="job-little-wrapper">
            {" "}
            {/* Added key here */}
            {selectedLanguage === "EN" ? (
              naslov === "Prazna stranica" ? (
                <h3 className="about-paragraph estate">
                  *There are currently no vehicles for sale*
                </h3>
              ) : (
                <Link to={`/${id}`} className="job">
                  <GatsbyImage
                    image={slika.gatsbyImageData}
                    alt={naslov}
                    className=""
                  />
                  <h5>{naslov}</h5>
                  {vrijediDo ? <p>The ad is open until {vrijediDo}.</p> : ""}
                </Link>
              )
            ) : naslov === "Prazna stranica" ? (
              <h3 className="about-paragraph estate">
                *Trenutno nema pokretnina koje su na prodaju*
              </h3>
            ) : (
              <Link to={`/${id}`} className="job">
                <GatsbyImage
                  image={slika.gatsbyImageData}
                  alt={naslov}
                  className=""
                />
                <h5>{naslov}</h5>
                {vrijediDo ? <p>Oglas je otvoren do {vrijediDo}.</p> : ""}
              </Link>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default MovablesList
