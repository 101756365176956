import React from "react"
import LayoutTwo from "../../components/LayoutTwo"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import AllMovables from "../../components/AllMovables"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"

const Movables = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <LayoutTwo>
      <SEO title="Pokretnine" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <div>
            <h2 className="about-header visibility-filter ">
              Vehicles for sale
            </h2>
            <h2 className="about-header mobile-visible">Vehicles</h2>
          </div>
        ) : (
          <div>
            <h2 className="about-header visibility-filter ">
              Pokretnine na prodaju
            </h2>
            <h2 className="about-header mobile-visible">Pokretnine</h2>
          </div>
        )}
      </div>
      <AllMovables />
    </LayoutTwo>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "pexels-mitchell-luo-3694708.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Movables
